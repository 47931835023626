.visually-hidden-title {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.search-button {
    background: #ffd617;
    color: #1f1f1f;
	cursor: pointer;
    line-height: 1.5;
    font-weight: 400;
    padding: 10px 30px;
    border-radius: 8px;
    border:0;
    font-size: 22px;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    transition: background-color 0.3s ease;
}

.search-button:hover {
    background: #D5CA08FF;
}

.search-button:disabled {
    /*opacity: 0.6; !* Example: reduce opacity *!*/
    /*cursor: not-allowed;*/
}

.search-input {
  border:0;
  border-bottom:1px solid #eee;
  margin-left: 10px;
  margin-right: 10px;
  height: 48px;
  width: 280px;
  font-size: 22px;
  font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
  text-transform: uppercase;
    padding-left: 10px
}

.search-input:focus {
    outline: none;
}

.search-form-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-form {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.input-error-container {
    position: relative;
}

.input-error {
    position: absolute;
    /*bottom: 20px;*/
    top: 52px;
    left: 20px;
    color: #FF5F5F;
    font-size: 15px;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;

}

.search-input.invalid {
    border: 2px solid #FF5F5F;
    border-radius: 8px;
}

.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%
}

.search-title {
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 40px;
    font-weight: 500;
}

.search-description {
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 22px;
    margin-top: 25px;
    font-weight: 400;
}

.search-label-text {
    font-size: 26px;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-weight: 600;
}

.custom-paypal-buttons {
    width: 600px;
    height: fit-content;
}

@media (max-width: 767px) {
    .search-form-label {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 80%;
    }

    .search-form {
        height: 67px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 20px;
        flex-direction: column;
    }

    .input-error-container {
        position: relative;
    }

    .search-button {
        background: #ffd617;
        color: #1f1f1f;
        cursor: pointer;
        line-height: 1.5;
        font-weight: 400;
        padding: 10px 30px;
        border-radius: 14px;
        border:0;
        font-size: 22px;
        font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
        transition: background-color 0.3s ease;
        width: 80%;
        margin-top: 25px;
    }

    .search-input {
        border:0;
        border-bottom:1px solid #eee;
        margin-right: 0;
        margin-left: 0;
        height: 48px;
        width: 100%;
        font-size: 22px;
        font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
        text-transform: uppercase;
        text-align: center;
        padding-left: 2px;
    }

    .search-title {
        font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
        font-size: 40px;
        font-weight: 500;
        line-height: 1.1;
        width: 80%;
    }

    .search-description {
        font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
        font-size: 22px;
        margin-top: 25px;
        font-weight: 400;
        line-height: 1.3;
        width: 80%;
    }

    .custom-paypal-buttons {
        width: 100%;
        height: fit-content;
    }

    .search-input.invalid {
        border: 2px solid #FF5F5F;
        border-radius: 8px;
    }

    .input-error-container {
        position: relative;
        width: 100%;
    }

    .search-label-text {
        font-size: 28px;
        font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
        margin-bottom: 5px;
        font-weight: 600;
        align-self: center;
    }
}