.pricing-title {
    font-weight: 700;
    text-transform: uppercase;
    color: #999;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 17px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.pricing-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: #f9f9f9;
}

.pricing-block {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
}

.price-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.price {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 27px;
    margin-right: 10px;
}

.pricing-block-text {
    font-size: 20px;
    font-weight: 400;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;

}

.price-label {
    font-size: 20px;
    font-weight: 400;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.prising-note {
    font-size: 16px;
    color: #999;
}

@media (max-width: 767px) {
    .pricing-container {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        background: #f9f9f9;
        margin-bottom: 50px;
    }
}