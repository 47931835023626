.privacy-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.privacy-text-container {
    width: 60%;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.privacy-text-container p {
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 400;
}

.privacy-header-text {
    font-size: 22px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 500;
    color: black;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.privacy-title-header-text {
    font-size: 27px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 500;
    color: black;
    padding-top: 12px;
    padding-bottom: 12px;
}

.privacy-list-item {
    font-size: 15px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 400;
    padding-top: 7px;
    padding-bottom: 7px;
    line-height: 1.3;
}

.privacy-last-update-text {
    margin-top: 15px;
}

@media (max-width: 767px) {
    .privacy-text-container {
        width: 75%;
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
}