.contact-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: #f9f9f9;
    position: relative;
}

.contact-main-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.contact-small-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    margin-top: 60px;
    height: 60%;
    border: 1px solid #e6e6e6;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    width: 30%;
    z-index: 2;
}

.contact-cloud-img {
    height: 33%;
}

.contact-text {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 18px;
    text-align: center;
}

.contact-us-title {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 22px;
    text-align: center;
}

.contact-email {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 20px;
    text-align: center;
    color: #2E6FBB;
}

@media (max-width: 767px) {
    .contact-small-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: #fff;
        margin-top: 60px;
        height: 60%;
        border: 1px solid #e6e6e6;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 15px;
        width: 80%;
        z-index: 2;
    }
}