.search-result-contailer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.search-result-info-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.search-result-info-section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 22px;
    font-weight: 400;
}

.search-result-extras-title {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
}

.search-result-extras-text {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-weight: 400;
}

.search-result-extras-item {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    height: 40px;
}

.search-result-extras-img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.search-result-extras {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.search-result-extras-internal-container {
    width: 45%;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 2 items per row */
    height: 100%; /* Ensure the container height allows for 20% spacing */
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10%;
}

.damage-warning-main-text {
    text-transform: uppercase;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 20px;
    color: red;
    margin-bottom: 5px;
}

.damage-warning-container {
    padding: 16px 20px;
    background-color: rgba(255, 0, 31, 0.02);
    border: 1px solid red;
    border-radius: 10px;
    width: 45%;
    text-align: center;
}

.damage-warning-medium-text {
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}

.damage-warning-small-text {
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 20px;
    font-weight: 500;
}

.search-result-info-section-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-right: 10px;
}

.search-result-info-text {
    margin-left: 5px;
    text-transform: lowercase; /* Makes all letters lowercase */
}

.search-result-info-text::first-letter {
    text-transform: uppercase; /* Capitalizes the first letter */
}

.search-result-title-container {
    margin-bottom: 20px;
}

.search-result-title {
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    color: green;
}

.search-result-not-found-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.search-result-not-found-title {
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.call-to-action-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
}

.call-to-action-text {
    font-size: 30px;
    font-weight: bold;
}

.search-result-big-title-image {
    width: 35px;
    height: 35px;
    margin-right: 7px;
}

.search-result-big-title-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.search-result-title-small {
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
}

@media (max-width: 767px) {
    .search-result-title {
        font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
        font-size: 25px;
        color: green;
        font-weight: 700;
        text-align: start;
    }

    .search-result-container {
        margin-top: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 80%;
    }

    .search-result-image-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .search-result-image {
        width: 100%;
    }

    .search-result-extras {
        width: 94%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .search-result-extras-internal-container {
        width: 97%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 0;
    }

    .search-result-extras-internal-container > * {
        flex: 1 1 calc(50% - 10px); /* Two items per row with some margin */
        margin: 5px;
    }

    .damage-warning-container {
        padding: 16px 20px;
        background-color: rgba(255, 0, 31, 0.02);
        border: 1px solid red;
        border-radius: 10px;
        width: 80%;
        text-align: center;
    }
}