.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 22%;
    margin-left: 77%;
    /*border-top: 1px solid #d5d5d5;*/
    color: #1f1f1f;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;

    text-align: center;
    z-index: 1000;
    background: #fff;
    border-radius: 8px;
    padding: 7px 12px 12px;
    transition: right 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 160px;
}

.cookie-banner-image-container {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cookie-image {
    max-width: 100%; /* Scale the image to fit the container width */
    max-height: 100%; /* Scale the image to fit the container height */
    width: auto; /* Ensure the image maintains its aspect ratio */
    height: auto; /* Ensure the image maintains its aspect ratio */
}

.cookie-consent-title {
    color: #4070f4;
    font-size: 28px;
    font-weight: 500;
}

.buttons {
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttons .button {
    border: none;
    color: #fff;
    padding: 8px 0;
    border-radius: 4px;
    background: #4070f4;
    cursor: pointer;
    width: calc(100% / 2 - 10px);
    transition: all 0.2s ease;
}
.buttons #acceptBtn:hover {
    background-color: #034bf1;
}
#declineBtn {
    border: 2px solid #4070f4;
    background-color: #fff;
    color: #4070f4;
}
#declineBtn:hover {
    background-color: #4070f4;
    color: #fff;
}

.buttons {
    font-size: 15px;
}


@media (max-width: 767px) {
    .cookie-consent-title {
        color: #4070f4;
        font-size: 32px;
        font-weight: 500;
    }
    .buttons {
        font-size: 18px;
    }
    .cookie-banner {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-left: 0;
        /*border-top: 1px solid #d5d5d5;*/
        color: #1f1f1f;
        font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 0;

        text-align: center;
        z-index: 1000;
        background: #fff;
        border-radius: 0;
        transition: right 0.3s ease;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 242px;
        padding: 7px 12px 1px;
    }
}

/* Media query for widths between 767px and 1200px (tablets and small desktops) */
@media (min-width: 768px) and (max-width: 900px) {
    .cookie-banner {
        width: 44%;
        margin-left: 55%;
    }
}

/* Media query for min-width 1200px (large desktops) */
@media (min-width: 901px) {
    .cookie-banner {
        width: 39%;
        margin-left: 60%;
    }
}

@media (min-width: 1201px) {
    .cookie-banner {
        width: 27%;
        margin-left: 72%;
    }
}

@media (min-width: 1501px) {
    .cookie-banner {
        width: 24%;
        margin-left: 75%;
    }
}

@media (min-width: 1701px) {
    .cookie-banner {
        width: 20%;
        margin-left: 79%;
    }
}