.header-container {
    width: 100%;
    /*height: 10%;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d5d5d5;
    background-color: rgba(196, 217, 234, .25);
    transition: background-color .2s ease;
    padding-left: 25px;
}

.header-list-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 2;
}

.header-list {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-list {
    list-style-type: none;
}

.header-list-container li {
    margin-right: 25px;

}

.header-logo-container {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    position: relative;
}

.logo-text {
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin-left: -10px;
}

.header-logo-container img {
    height: 100%;
    width: 25%;
    /* border-radius: 50%; */
}

.header-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
}

.header-logo-container:hover {
    cursor: pointer;
}

.header-list-item {
    color: #1f1f1f;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 20px;
    display: inline-block;
    position: relative; /* Required for pseudo-element */
    cursor: pointer;
}

/* Create a pseudo-element for the border */
.header-list-item::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px; /* Adjust as needed */
    width: 100%;
    height: 1px; /* Thickness of the border */
    background-color: transparent; /* Initial color */
    transition: background-color 0.3s ease; /* Smooth transition */
}

.header-logo-img {
    margin-right: 5px;
    min-height: 80px;
    min-width: 120px;
}

/* Change the border color on hover */
.header-list-item:hover::after,
.header-list-item:focus::after {
    background-color: #1f1f1f; /* Adjust color as needed */
}

.header-list-item.active::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px; /* Adjust the thickness of the line as needed */
    background-color: #1f1f1f; /* Color of the line */
    position: absolute;
    bottom: 0;
    left: 0;
}

/* Mobile styles */
@media (max-width: 767px) {
    .header-list-container {
        display: none; /* Hide the navbar on mobile */
    }

}