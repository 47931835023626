.global-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 120px;
}

.main-page-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.global-container-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    flex-grow: 1;
    margin-bottom: 30px;
    padding: 10px;
}

.global-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 40%;
}

.global-container-item img {
    width: 100%;
}

.global-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 40%;
    width: 70%;
}

.global-text-title {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 35px;
    font-weight: 500;
}

.global-text-description {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 22px;
    margin-top: 25px;
    font-weight: 400;
    width: 80%;
}

.find-vin-container {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 30px;
}

.find-vin-images {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.find-vin-title {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 20px;
}

.find-vin-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    height: 200px;
}

.find-vin-description {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 25px;
    width: 90%;
    height: 50%;
}

.find-vin-small-text {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-weight: 400;
    padding: 5px;
    text-align: center;
}

@media (max-width: 767px) {
    .global-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
    }

    .main-page-info-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 40px;
    }

    .global-text-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 40%;
        width: 100%;
    }

    .global-container-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-grow: 1;
        margin-bottom: 30px;
        padding: 10px;
        flex-direction: column;
    }

    .global-container-item:nth-child(2),
    .global-container-item:nth-child(4) {
        flex-direction: column-reverse;
    }

    .global-text-description {
        font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 22px;
        margin-top: 25px;
        font-weight: 400;
        width: 80%;
    }

    .global-container-item img {
        width: 80%;
    }

    .global-text-title {
        font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 35px;
        font-weight: 500;
        width: 80%;
    }

    .find-vin-images {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2 elements per row, each taking 1 fraction of the available space */
        gap: 16px; /* Adjust the gap between items as needed */
        width: 100%;
        row-gap: 24px;
    }
}
