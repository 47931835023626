.car-name-title {
    font-size: 55px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
}

.car-vin-name {
    font-size: 20px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    padding-top: 20px
}

.car-vin-value {
    font-size: 30px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    padding-top: 5px;
}

.report-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #f9f9f9;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.report-and-download-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.download-container {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
}

.report-title-container {
    display: flex;
    flex-direction: column;
}

.report-container-main-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 15px;
}

.your-car-overview-wrapper {
    width: 90%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.your-car-overview-content-container {
    display: flex;
    width: 100%;
}

.your-car-overview-title {
    font-weight: 700;
    text-transform: uppercase;
    color: #999;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.your-car-overview-content-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    margin: 20px;
    height: 350px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    padding: 15px;
    flex: 1 1 0;
    width: 0;
}

.general-icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

.your-car-overview-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
}

.your-car-overview-description-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid #e6e6e6;
}

.your-car-overview-big-text {
    font-size: 22px;
    line-height: 1.5;
    font-weight: 600;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.your-car-overview-small-text {
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.4;
}

/* Additional info*/

.additional-info-container {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin-top: 50px;
}

.additional-info-title {
    font-size: 25px;
    font-weight: 500;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.additional-info-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.25em 0;
    border-top: 1px solid #e6e6e6;
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.additional-info-title-container {
    width: 60%;
}

.additional-info-value-container {
    width: 40%;
}


/* Usage and ownership*/

.usage-and-ownership-item {
    display: flex;
    padding: 1.250em 0;
    border-top: 1px solid #e6e6e6;
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    width: 100%;
}

.usage-and-ownership-container {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin-top: 50px;
}

.usage-and-ownership-item-label {
    width: 60%;
    display: flex;
    align-items: center;
}

.usage-and-ownership-item-value {
    width: 40%;
    display: flex;
    align-items: center;
}

.usage-and-ownership-title {
    font-size: 25px;
    font-weight: 500;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #1f1f1f;
    margin-bottom: 30px;
}

.usage-and-ownership-item-value img {
    height: 35px;
    margin-right: 10px;
}

.pale-background {
    background: rgba(0, 0, 0, .02);
}

/*Mileage*/

.mileage-title {
    font-size: 25px;
    font-weight: 500;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #1f1f1f;
    margin-bottom: 10px;
    margin-top: 50px;
}

.mileage-wrapper {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin-top: 15px;
}

.mileage-item {
    display: flex;
    padding: 1.250em 0;
    border-top: 1px solid #e6e6e6;
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    width: 100%;
}

.mileage-container {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin-top: 50px;
}

.mileage-item-label {
    width: 60%;
    display: flex;
    align-items: center;
}

.mileage-item-value {
    width: 40%;
    display: flex;
    align-items: center;
}

.mileage-header-text {
    font-size: 19px;
    font-weight: 500;
}

.odometer-rollback-warning-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    margin-left: 7px;
}

.odometer-rollback-warning-container p {
    margin-left: 7px;
}

.mileage-facts-title {
    font-size: 19px;
    font-weight: 500;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    margin-top: 40px;
    margin-bottom: 20px;
}

.mileage-facts-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mileage-facts-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.25em 0;
    border-top: 1px solid #e6e6e6;
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.mileage-facts-title-container {
    width: 60%;
}

.mileage-facts-value-container {
    width: 40%;
}

.mileage-facts-item:nth-child(2) {
    background: rgba(0, 0, 0, .02);
}

.mileage-checks-container {
    display: flex;
    padding: 1.250em 0;
    border-top: 1px solid #e6e6e6;
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    width: 100%;
}

.mileage-checks-label {
    width: 60%;
    display: flex;
    align-items: center;
}

.mileage-checks-value {
    width: 40%;
    display: flex;
    align-items: center;
}

.mileage-checks-title {
    font-size: 25px;
    font-weight: 500;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    margin-top: 50px;
    margin-bottom: 20px;
}

.mileage-checks-value img {
    height: 35px;
    margin-right: 10px;
}

.damage-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.damage-big-title {
    font-size: 25px;
    font-weight: 500;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #1f1f1f;
    margin-top: 50px;
    margin-bottom: 30px;
}

.damage-model-img {
    max-width: 65%;
    height: auto;
}

.damage-model-image-container {
    width: 45%;
}

.damage-content-container {
    display: flex;
}

.damage-description-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 50px;
}

.damage-description-item p {
    font-size: 19px;
    font-weight: 400;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.damage-description-item {
    display: flex;
    align-items: center;
    padding: 1.250em 0;
    border-top: 1px solid #e6e6e6;
}

.damage-description-item-label {
    width: 50%;
}

.damage-description-item-value {
    display: flex;
    align-items: center;
    width: 50%;
}

.damage-status-value-damaged {
    color: red;
}

.damaged-status-image {
    width: 37px; /* Set the desired width */
    height: auto; /* Automatically adjust the height to maintain aspect ratio */
    margin-left: 5px;
}

.not-damaged-status-image {
    width: 37px; /* Set the desired width */
    height: auto; /* Automatically adjust the height to maintain aspect ratio */
    margin-left: 5px;
}

.damage-description-item:nth-child(2){
    background: rgba(0, 0, 0, .02);
}

.damage-additional-records-container {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.damage-additional-records-title {
    font-size: 25px;
    font-weight: 500;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    margin-top: 50px;
    margin-bottom: 30px;
}

.damage-additional-records-items {
    display: flex;
    padding: 1.250em 0;
    border-top: 1px solid #e6e6e6;
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    width: 100%;
}

.damage-additional-records-label {
    width: 60%;
    display: flex;
    align-items: center;
}

.damage-additional-records-value {
    width: 40%;
    display: flex;
    align-items: center;
}

.damage-additional-records-value img {
    height: 35px;
    margin-right: 10px;
}

.pdf-download-button {
    background: #ffd617;
    color: #1f1f1f;
    cursor: pointer;
    line-height: 1.5;
    font-weight: 400;
    padding: 10px 30px;
    border-radius: 8px;
    border:0;
    font-size: 20px;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    transition: background-color 0.3s ease;
}

.pdf-download-button:hover {
    background: #D5CA08FF;
}

.sample-report-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    /*min-height: 540px;*/
}

.report-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .your-car-overview-content-container {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .your-car-overview-content-item {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: #fff;
        margin: 20px 0 0;
        height: 350px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        padding: 15px;
        flex: 1 1 0;
        width: 100%;
    }

    .car-vin-value {
        font-size: 30px;
        font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .mileage-item-value {
        width: 40%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .odometer-rollback-warning-container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: red;
        margin-left: 7px;
        flex-direction: column;
    }

    .odometer-rollback-warning-container-text {
        text-align: center;
    }

    .odometer-rollback-warning-container p {
        margin-left: 0;
    }

    .damage-content-container {
        display: flex;
        flex-direction: column;
    }


    .damage-description-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .damage-model-image-container {
        width: 100%;
    }

    .damage-model-img {
        max-width: 100%;
        height: auto;
    }


    .mileage-facts-title-container {
        width: 73%;
        margin-right: 15px;
    }

    .mileage-facts-value-container {
        width: 27%;
    }
}