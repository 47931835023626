.notfound-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: #f9f9f9;
    position: relative;
}

.notfound-small-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    margin-top: 60px;
    height: 60%;
    border: 1px solid #e6e6e6;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    width: 30%;
    z-index: 2;
}

.notfound-big-text {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 60px;
    text-align: center;
}

.notfound-medium-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 22px;
    text-align: center;
}

.notfound-visit-home-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 22px;
    text-align: center;
}

.notfound-small-text {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 20px;
    text-align: center;
    margin-right: 6px;
}

.horizontal-line {
    width: 90%;
    height: 1px; /* Adjust the height as needed */
    background-color: #D8D8D8; /* Change the color as needed */
}