.about-us-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-direction: column;
}

.about-intro {
    display: flex;
    width: 80%;
    min-height: 350px;
    margin-bottom: 50px;
}

.intro-section-image {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.intro-section {
    width: 40%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
}

.intro-description {
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 400;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: justify;
}

.intro-description-italic {
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 400;
    padding-top: 15px;
    padding-bottom: 15px;
    font-style: italic;
    text-align: justify;
}

.intro-image-container {
    width: 80%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.about-us-intro-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.about-us-title-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
}

.about-us-title-text {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 40px;
    font-weight: 300;
}

.testimonials-medium-text {
    font-size: 18px;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 400;
}

.testimonials-big-text {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-weight: 300;
}

.rounded-circle {
    margin-top: 30px;
}

.testimonials {
    display: flex;
    width: 80%;
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .about-intro {
        display: flex;
        width: 80%;
        min-height: 350px;
        margin-bottom: 0;
        flex-direction: column-reverse;
    }

    .intro-section {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-direction: column;
    }

    .intro-image-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    .intro-section-image {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 25px;
    }

    .testimonials {
        display: flex;
        width: 80%;
        margin-bottom: 0;
    }
}