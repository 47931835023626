.footer-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d5d5d5;
    transition: background-color .2s ease;
    color: #1f1f1f;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 17px;
    padding-bottom: 10px;
    padding-top: 20px;
    background-color: #15171A;
    flex-direction: column;
}

.footer-list-container {
    width: 80%;
    display: flex;
    margin-bottom: 40px;
}

.footer-group {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
}

.footer-list {
    display: flex;
    justify-content: space-between;
    color: #fff;
    flex-direction: column;
    font-weight: 300;
}

.footer-list-item {
    margin-top: 10px;
    opacity: 90%;
    cursor: pointer;
    transition: color 0.3s ease;
}

.footer-list-item:hover {
    color: #638585;
}

.copyright-text {
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 15px;
    text-align: center;
    color: #999;
}

.footer-group-title {
    color: #638585;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 17px;
}

@media (max-width: 767px) {
    .footer-list-container {
        width: 80%;
        display: flex;
        margin-bottom: 40px;
        justify-content: space-around;
    }

    .footer-group {
        display: flex;
        flex-direction: column;
    }
}