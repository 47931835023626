/*Hide burger menu on non mobile screens*/
.burger-menu {
    display: none;
}

@media (max-width: 767px) {
    .burger-menu {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1001; /* Ensure the burger icon is above the menu */
    }

    .burger-menu-list {
        display: flex;
        overflow: hidden;
        position: fixed;
        top: 90px;
        left: 0;
        width: 100%;
        height: 0;
        background-color: rgba(0, 0, 0, 0.8);
        /*background-color: #F0F5FA;*/
        list-style-type: none;
        padding: 0;
        margin: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: height 0.5s ease;
        z-index: 1000; /* Ensure the menu is above other content */
    }

    /* Show the menu list with opacity transition */
    .burger-menu-list.open {
        visibility: visible;
        flex-direction: column;
        height: 100%;
        transition: height 0.5s ease;
    }

    .header-list-item-hidden {
        color: white;
        /*color: #4f4f4f;*/
        font-size: 2rem;
        margin: 2rem 0;
        cursor: pointer;
        transition: color 0.3s;
        visibility: hidden;
    }

    .header-list-item {
        color: white;
        /*color: #4f4f4f;*/
        font-size: 2rem;
        margin: 1rem 0;
        cursor: pointer;
        transition: color 0.3s;
    }

    .header-list-item:hover {
        color: #ccc;
    }

    body.no-scroll {
        overflow: hidden;
    }

    /* Add a transition for the burger icon */
    .hamburger-react .hamburger-inner,
    .hamburger-react .hamburger-inner::before,
    .hamburger-react .hamburger-inner::after {
        background-color: white;
    }
}

