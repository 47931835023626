.full-report-container {
    background-color: rgba(196, 217, 234, .25);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px 40px;
    height: 400px;
    min-height: 400px;
    margin-top: 10px;
}

.full-report-container-title {
    margin-top: 20px;
    margin-bottom: 10px;
}

.full-report-items-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    justify-items: flex-start;
    column-gap: 15px;
    row-gap: 25px;
    width: 60%;
    margin-bottom: 25px;
}

.full-report-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    margin-right: 10px;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 22px;
    font-weight: 400;
}

.full-report-item-image {
    width: 50px;
    height: 50px;
    margin-right: 7px;
}

.full-report-title-container {
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 15px;
}


.nav-button {
    background: #ffd617;
    color: #1f1f1f;
    cursor: pointer;
    line-height: 1.5;
    font-weight: 400;
    padding: 10px 30px;
    border-radius: 8px;
    border:0;
    font-size: 22px;
    font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
    transition: background-color 0.3s ease;
    width: 600px;
    text-align: center;
    margin-bottom: 15px;
}

.nav-button:hover {
    background: #D5CA08FF;
}

.nav-button:disabled {
    /*opacity: 0.6; !* Example: reduce opacity *!*/
    /*cursor: not-allowed;*/
}

@media (max-width: 767px) {
    .full-report-container {
        background-color: rgba(196, 217, 234, .25);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        height: fit-content;
        min-height: 260px;
        margin-top: 10px;
    }

    .full-report-items-container {
        display: flex;
        flex-wrap: wrap; /* Allows items to wrap to the next line */
        justify-content: space-between; /* Distributes space between items */
        align-items: center; /* Aligns items vertically in the center */
        gap: 30px 5%; /* Sets the vertical (row) and horizontal (column) gaps */
        width: 100%;
        margin-bottom: 25px;
        height: 100%;
    }

    .full-report-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 15px;
        margin-right: 7px;
        font-family: Inter,Helvetica Neue,Helvetica,Arial,sans-serif;
        font-size: 22px;
        font-weight: 400;
        width: 43%;
    }

    .nav-button {
        width: calc(100% + 40px);
        margin-top: 20px;
        margin-bottom: 0;
    }
}