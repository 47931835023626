.buy-full-report {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-right: 10px;
    color: green;
}

.discounted-price-text {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 5px;
}

del, ins {
    text-decoration: none;
}

.discounted-price-old-amount {
    color: rgba(255, 0, 0, 0.5);
    text-decoration: none;
    position: relative;
    font-size: 25px;
    &:before {
        content: " ";
        display: block;
        width: 100%;
        border-top: 2px solid rgba(255, 0, 0, 0.8);
        height: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: rotate(-7deg);
    }
}

.discounted-price-new-amount {
    color: green;
    font-size: 32px;
    text-decoration: none;
    padding: 1em 1em 1em .5em;
}

.buy-full-report-small-title {
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 10px;
}


.price-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    flex-direction: column;
    margin-bottom: 10px;
}

.discounted-price-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
}

.final-price-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /*flex-direction: column;*/
    margin-top: 10px;
    flex-direction: row;
    margin-bottom: 10px;
}

.currencies-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: 15%;
}

.price-small-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: 15%;
    margin-top: 5px;
}